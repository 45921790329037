import { ValidationMixin } from '@/shared/mixins';

export default class CreerSeuilMinimumOperationValidation extends ValidationMixin {

    /**
     * Vérifie la date d'activation.
     */
    public dateActivationSeuilRules = [
        (v: string | any) => this.required(v) || 'La date d\'activation est obligatoire',
        (v: string | any) => this.isDateNonPasse(v) || 'La date d\'activation n\'est pas valide, elle ne peut pas être dans le passé',
    ];

    /**
     * Vérifie la valeur du seuil.
     */
    public valeurSeuilRules = [
        (v: string | any) => this.required(v) || 'La valeur du seuil est obligatoire',
        (v: string | any) => this.isNumeric(v) || 'La valeur du seuil n\'est pas valide',
        (v: string | any) => this.isLessThanOrEqualTo(v, 100000) || 'La valeur du seuil est trop élevée',
    ];
}