import { Component } from 'vue-property-decorator';
import CreerSeuilMinimumOperationValidation from './CreerSeuilMinimumOperation.Validation';
import template from './CreerSeuilMinimumOperation.Template.vue';
import CeeDatePicker from '@/components/CeeDatePicker.vue'
import { SeuilMinimumOperation } from '@/models';
import { ApiService } from '@/services/base/ApiService';

@Component({
    ...template,
    name: 'CreerSeuilMinimumOperation',
    components: { CeeDatePicker },
})
export default class CreerSeuilMinimumOperation extends CreerSeuilMinimumOperationValidation {
    public $refs!: {
        form: HTMLFormElement,
    };

    // Le modèle du formulaire.
    public seuil: SeuilMinimumOperation = new SeuilMinimumOperation();

    public loading: boolean = false;

    public mounted() {
        // On crée un seuil par défaut avec la date du jour.
        this.seuil.dateActivation = new Date().toISOString().substr(0, 10);
    }

    // Ajout du seuil sur une soumission.
    public submit() {

        if (this.$refs.form.validate()) {
            this.loading = true;
            const apiAjout = new ApiService<SeuilMinimumOperation>('seuilMinimumOperation/ajouterSeuilMinimumOperation');

            apiAjout.post(this.seuil).then((result: any) => {
                if (result) { (this.$router as any).push({ name: 'gestion-seuil-minimum-operation' }); }
            }).finally(() => this.loading = false);
        }
    }
}

